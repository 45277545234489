
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'

  import { Aggregate, AggregateType, Filter } from '@/entities/public/Resource/interfaces'
  import { AggregateComponent, AggregateComponentType } from '@/components/aggregates'
  import { Debounce } from '@/utils/decorators'
  import { Period } from '@/store/resources/period'
  import MainSearch from '@/components/dataTables/search/MainSearch.vue'

interface AggregateInput extends AggregateComponent {
  values: Array<AggregateType>
  index: number
  loading: boolean
}

@Component({
  components: {
    MainSearch,
    PeriodSelector: () => import('@/components/core/PeriodSelector.vue'),
    CategoryDistribution: () => import('../aggregates/CategoryDistribution.vue'),
    EmployeesBoard: () => import('../aggregates/EmployeesBoard.vue'),
  },
  methods: {
    ...mapActions('resources/datatables', ['fetchAggregates']),
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class MiniDataPanel extends Vue {
  @Prop({ type: Object }) filter!: Filter
  @Prop({ type: Array }) aggregates!: Array<Aggregate>
  @Prop({ type: Object }) period!: Period
  @Prop({ type: Boolean, default: false }) isCustomSearch!: boolean
  @Prop({ type: Boolean, default: true }) showPeriod!: boolean

  loading = false

  components: Array<AggregateInput> = []
  fetchAggregates!: (payload: {
    aggregate: Aggregate,
    period: Period,
    filter?: Filter
  }) => Promise<Array<AggregateType>>

  get dataChanged () {
    const { aggregates, filter, period, showPeriod } = this
    return { aggregates, filter, period, showPeriod }
  }

  mounted () {
    this.search = this.$route?.query?.search || ''
    if (this.search?.length) {
      this.searchEmit()
    }
  }

  @Watch('dataChanged', { deep: true, immediate: true })
  @Debounce(500)
  async setComponents ({ aggregates, filter, period }) {
    // TODO(performance): Compute which are not going to change
    if (!aggregates) return this.components = []
    if (!period?.where) return

    this.components = await Promise.all(aggregates.map(async (aggregate, index) => {
      const { component, options } = aggregate
      if (component in AggregateComponentType) throw new Error(`${component} is not a valid Aggregate Component`)

      let customPeriod = period
      if (!this.showPeriod) {
        customPeriod = {}
      }

      const values = await this.fetchAggregates({ aggregate, period: customPeriod, filter })
      return {
        index,
        loading: false,
        component,
        options,
        values,
      }
    }))
    this.loading = false
  }

  get errorMessage () {
    const { isCustomSearch } = this

    return !isCustomSearch ? 'No hay busquedas definidas para este DT' : ''
  }
  }
