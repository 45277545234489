export enum AggregateComponentType {
  employeesBoard = 'employees-board',
  categoryDistribution = 'category-distribution'
}

// ---------------------------------------------------------------------------------------------------------------------
export interface ItemConfig {
  hidden?: boolean
  icon?: string
  title?: string
}

export interface CollapsedItemsOptions extends ItemConfig {
}

export interface EmployeesBoardOptions {
  limit?: number
  others?: CollapsedItemsOptions
}

// ---------------------------------------------------------------------------------------------------------------------
export interface CategoryDistributionOptions {
  total?: ItemConfig
  overwrite?: Record<string, string>
}

type AggregateComponentOptions = EmployeesBoardOptions | CategoryDistributionOptions

export interface AggregateComponent {
  component?: AggregateComponentType
  options?: AggregateComponentOptions
}
