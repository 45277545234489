
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { FilterField } from '@/entities/public/Resource/interfaces'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: {
    GCostField,
    VTextField: () => import('vuetify/lib/components/VTextField'),
    VCombobox: () => import('vuetify/lib/components/VCombobox'),
    VDivider: () => import('vuetify/lib/components/VDivider'),
  },
})
  export default class AdvanceSearch extends Vue {
  @Prop({ required: true })
  filterFields!: FilterField[];

  // Almacenar valores internos en lugar de depender de filterFields directamente
  internalValues: Array<any> = [];

  created () {
    // Inicializamos los valores internos según los campos de filtro
    this.internalValues = this.filterFields.map(field => field.value || null)
  }

  get isSearchEnabled (): boolean {
    return this.internalValues.some(value => value !== undefined && value !== null && value !== '')
  }

  getComponentType (field: FilterField): string {
    return field.component || 'v-text-field'
  }

  onFieldChange (field: FilterField, index: number) {
    if (field.querySearch) {
      this.fetchItems(field)
    }
  }

  fetchItems (field: FilterField) {
    // Implementa la lógica de obtener los ítems basados en field.querySearch
    // Ejemplo:
    // this.$api.fetch(field.querySearch).then(response => {
    //   field.items = response.data;
    // });
  }

  onClear () {
    this.internalValues = this.filterFields.map(() => null)
  }

  onCancel () {
    this.$emit('cancel')
  }

  onSearch () {
    const searchCriteria = this.filterFields.map((field, index) => ({
      ...field,
      value: this.internalValues[index],
    }))
    this.$emit('search', searchCriteria)
  }
  }
